body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f9f9f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ProseMirror code.code-hightlight {
  background: #ddd;
  color: red;
  font-size: 0.8rem;
  padding: 0.2rem 0.4rem;
}

.ProseMirror pre.code-block-highlight {
  background: #0d0d0d;
  border-radius: 0.5rem;
  color: #fff;
  font-family: "JetBrainsMono", monospace;
  padding: 0.75rem 1rem;
}

.ProseMirror pre.code-block-highlight code {
  background: none;
  color: inherit;
  font-size: 0.8rem;
  padding: 0;
}

.ProseMirror pre.code-block-highlight .hljs-comment,
.ProseMirror pre.code-block-highlight .hljs-quote {
  color: #616161;
}

.ProseMirror pre.code-block-highlight .hljs-variable,
.ProseMirror pre.code-block-highlight .hljs-template-variable,
.ProseMirror pre.code-block-highlight .hljs-attribute,
.ProseMirror pre.code-block-highlight .hljs-tag,
.ProseMirror pre.code-block-highlight .hljs-name,
.ProseMirror pre.code-block-highlight .hljs-regexp,
.ProseMirror pre.code-block-highlight .hljs-link,
.ProseMirror pre.code-block-highlight .hljs-name,
.ProseMirror pre.code-block-highlight .hljs-selector-id,
.ProseMirror pre.code-block-highlight .hljs-selector-class {
  color: #f98181;
}

.ProseMirror pre.code-block-highlight .hljs-number,
.ProseMirror pre.code-block-highlight .hljs-meta,
.ProseMirror pre.code-block-highlight .hljs-built_in,
.ProseMirror pre.code-block-highlight .hljs-builtin-name,
.ProseMirror pre.code-block-highlight .hljs-literal,
.ProseMirror pre.code-block-highlight .hljs-type,
.ProseMirror pre.code-block-highlight .hljs-params {
  color: #fbbc88;
}

.ProseMirror pre.code-block-highlight .hljs-string,
.ProseMirror pre.code-block-highlight .hljs-symbol,
.ProseMirror pre.code-block-highlight .hljs-bullet {
  color: #b9f18d;
}

.ProseMirror pre.code-block-highlight .hljs-title,
.ProseMirror pre.code-block-highlight .hljs-section {
  color: #faf594;
}

.ProseMirror pre.code-block-highlight .hljs-keyword,
.ProseMirror pre.code-block-highlight .hljs-selector-tag {
  color: #70cff8;
}

.ProseMirror pre.code-block-highlight .hljs-emphasis {
  font-style: italic;
}

.ProseMirror pre.code-block-highlight .hljs-strong {
  font-weight: 700;
}